/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../configurator/App.css"
import "./login.css";
import ReactLoading from "react-loading";
import { GLTFLoader } from "../../configurator/Exporter/GLTFLoader";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { isAuth, setGender, setLocalStorage } from "../helpers/auth";
import { Redirect } from "react-router-dom";
import { initialData, initialState } from "../../configurator/State";
const dotenv = require("dotenv");
dotenv.config({ path: "../../.env" });

export const retrieveStateFromModel = async (
  retrievedState,
  gender,
  setRetrieved
) => {
  setLocalStorage("data", initialData);
  // console.log("Saved the initialData to localStorage");
  try {
    console.log(retrievedState);
    if (retrievedState) {
      setLocalStorage("state", retrievedState);
      setRetrieved(true);
      setGender(gender);
      console.log("Saved the retrievedModelState to localStorage");
      setLocalStorage("retrievedState", true);
      console.log("EXECUTED HERE NOW 1");
    } else {
      setLocalStorage("state", initialState);
      // console.log("Saved the initialState to localStorage");
    }
  } catch (error) {
    console.error("Failed to retrieve state from the model:", error);
  }
};

const Login = ({ history, username, setUserName }) => {
  const [done, setDone] = useState(false);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retrieved, setRetrieved] = useState(false);

  function modelLoader(loader) {
    return new Promise((resolve, reject) => {
      loader.load(
        "/",
        (data) => resolve(data),
        (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        reject
      );
    });
  }

  async function pullStateFromModel(filepath, file) {
    const loader = new GLTFLoader().setPath(`${filepath + file}`);
    const gltf = await modelLoader(loader);
    // console.log("COMPLETED LOADING MODEL");
    const data = JSON.parse(gltf.scene.children[0].userData.name);
    retrieveStateFromModel(data.avatar, data.gender, setRetrieved);
  }

  const getUserInfo = async (accessToken, filepath, file) => {
    const headers = { Authorization: `Bearer ${accessToken}` };
    await axios
      .get(`${process.env.REACT_APP_API_URL}/user/user_info`, {
        headers,
      })
      .then((res) => {
        // console.log(res.data.data.username);
        const username = res.data.data.username;
        setLocalStorage("user", username);
        setUserName(username);
        if (filepath !== null || file !== null) {
          pullStateFromModel(filepath, file);
        } else {
          setLocalStorage("data", initialData);
          setLocalStorage("state", initialState);
          history.push("/gender");
        }
        setDone(true);
        setFinished(true);
      })
      .catch((error) => {
        console.log("GETTING USER INFO ERROR, Strict Mode");
      });
  };

  const Login = (filepath, file) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, {
        email: "normal@vrpanda.org",
        password: "12345678",
      })
      .then((res) => {
        // console.log(res.data.data.access_token);
        const accessToken = res.data.data.access_token;
        setLocalStorage("Admin-Token", accessToken);
        getUserInfo(accessToken, filepath, file);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors);
      });
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const path = searchParams.get("path");
    if (path !== null) {
      console.log("PATH FOUND");
      const regex = /avatar/;
      const startIndex = path.search(regex);
      const filepath = path.slice(0, startIndex);
      const file = path.slice(startIndex);
      Login(filepath, file);
    } else {
      console.log("NO PATH FOUND");
      Login(null, null);
    }
  }, []);

  const className = `min-h-screen bg-gray-100 text-gray-900 flex justify-center  ${
    !done ? "items-center" : " "
  }`;

  return (
    <div className={className}>
      {loading && !done && (
        <div className="flex flex-col">
          <ReactLoading
            type={"spin"}
            color={"#4338ca"}
            height={100}
            width={100}
          />
          <h1 className=" text-2xl xl:text-3xl log-in-text">Signing in</h1>
        </div>
      )}
      {done && retrieved && isAuth() && finished ? (
        <Redirect to="/configurator" />
      ) : null}
      <ToastContainer />
    </div>
  );
};

export { Login };
