/* eslint-disable */
import { useThree } from "@react-three/fiber";
import { GLTFExporter } from "./GLTFExporter";
import { saveAs } from "file-saver";
import { animationClips } from "../Models/Web_Rig/Avatar";
import exportAsImage from "./AvatarImage/exportAsImage";
export function Foo({
  clicked,
  setClicked,
  exportRef,
  setState,
}) {
  const { scene } = useThree();
  if (clicked) {
    var avatarObject = scene.getObjectByProperty("avatar", "avatar");
    const exporter = new GLTFExporter();
    const clips = [animationClips[localStorage.getItem("animation")]];
    exporter.parse(
      avatarObject,
      async function (glb) {
        const blobURL = URL.createObjectURL(
          new Blob([glb], {
            type: "application/octet-stream",
          })
        );

        saveAs(
          blobURL,
          `avatar_${animationClips[localStorage.getItem("animation")].name}.glb`
        );
        setTimeout(() => {
          exportAsImage(exportRef.current, "test");
          setTimeout(() => {
            setState((prevState) => ({
              ...prevState,
              appliedAnimation: JSON.parse(localStorage.getItem("animation")),
            }));
          }, 1000);
        }, 2000);
      },
      function () {
        console.log("There is an error");
      },
      {
        binary: true,
        animations: clips, //should be false if you need a GLTF Format file
      }
    );
    setClicked(!clicked);
  }
}
