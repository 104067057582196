import React, { useState,  useLayoutEffect } from "react";
import "./index.css";

import { useTransition, a } from "react-spring";
import {useProgress } from "@react-three/drei";

const Loading = ({ manager }) => {
  const [finished, set] = useState(false);
  const [loadingClassName, setLoad] = useState("loading");
  const [width, setWidth] = useState(0);
  const { progress, loaded, total } = useProgress();


  useLayoutEffect(() => {
    setWidth((loaded / total) * 200);
    if (progress === 100 && loaded === total && !finished) {
      set(true);
      setTimeout(() => {
        setLoad("loading done-loading");
      }, 1000);
    }
  }, [progress,loaded,total,finished]);

  const props = useTransition(finished, null, {
    from: { opacity: 1, width: 0 },
    leave: { opacity: 0 },
    update: { width },
  });

  return props.map(
    ({ item: finished, key, props: { opacity, width } }) =>
      !finished && (
        <a.div className={loadingClassName} key={key} style={{ opacity }}>
          <div className="welcome text-center">Web Avatar</div>
          <div className="loading-bar-container">
            <a.div className="loading-bar" style={{ width }} />
          </div>
        </a.div>
      )
  );
};

export default Loading;
