/* eslint-disable */
import "./index.css";
import "../../index.css";
import "./App.css";
import * as THREE from "three";
import { Suspense, useState, useRef, useEffect } from "react";
import useStateSnapshots from "use-state-snapshots";
import { Canvas, useThree, extend, useFrame } from "@react-three/fiber";
import CameraControls from "camera-controls";
import { Foo } from "./Exporter";
import { useCallback } from "react";
import throttle from "lodash/throttle";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./ScrollBar/arrows";
import useDrag from "./ScrollBar/useDrag";
import "./ScrollBar/globalStyles.css";
import usePreventBodyScroll from "./ScrollBar/usePreventBodyScroll";
import "./ScrollBar/hideScrollbar.css";
import { FaceObject, face_Objects } from "./Models/Face/swatch";
import { BeardObject, beard_Objects } from "./Models/Beard/swatch";
import { CheekObject, cheek_Objects } from "./Models/Cheek/swatch";
import { EyesObject, eyes_Objects } from "./Models/Eye/swatch";
import { EyeBrowObject, eyeBrow_Objects } from "./Models/EyeBrow/swatch";
import { NoseObject, nose_Objects } from "./Models/Nose/swatch";
import { MouthObject, mouth_Objects } from "./Models/Mouth/swatch";
import {
    AccessoryObject,
    accessory_Objects,
} from "./Models/Accessories/swatch";
import {
    BoyHairStyleObject,
    boyHairStyle_Objects,
} from "./Models/HairStyle/Boy/swatch";
import {
    GirlHairStyleObject,
    girlHairStyle_Objects,
} from "./Models/HairStyle/Girl/swatch";

import { ShoeObject, shoes_Objects } from "./Models/Shoes/swatch";
import {
    BoyLowerClothObject,
    boyLowerClothes_Objects,
} from "./Models/Clothes/Boy/Lower/swatch";
import {
    BoyUpperClothObject,
    boyUpperClothes_Objects,
} from "./Models/Clothes/Boy/Upper/swatch";
import {
    GirlLowerClothObject,
    girlLowerClothes_Objects,
} from "./Models/Clothes/Girl/Lower/swatch";
import {
    GirlUpperClothObject,
    girlUpperClothes_Objects,
} from "./Models/Clothes/Girl/Upper/swatch";
import {
    GirlFullClothObject,
    girlFullClothes_Objects,
} from "./Models/Clothes/Girl/Full/swatch";
import {
    BoyOption,
    boyFaceInactive,
    boyBodyInactive,
    maleFaceOption,
    maleBodyOption,
} from "./Options/Boy/swatch";
import {
    GirlOption,
    girlFaceInactive,
    girlBodyInactive,
    femaleFaceOption,
    femaleBodyOption,
} from "./Options/Girl/swatch";
import { glasses_Objects, GlassObject } from "./Models/Glasses/swatch";
import {
    BackButton,
    UploadButton,
    SearchButton,
    DownloadButton,
    ResetButton,
    SaveButton,
    HomeButton,
    HeadButton,
    BodyButton,
    AnimationButton,
    VRPanda,
} from "./Buttons";
import {
    BoyHairColorObject,
    boyHairColor_Objects,
} from "./Models/HairStyle/Boy/color";
import { BodyColorObject, bodyColor_Objects } from "./Models/Body/color";
import {
    GirlHairColorObject,
    girlHairColor_Objects,
} from "./Models/HairStyle/Girl/color";
import Lights from "./Models/Lights";
import Loading from "./Loading";
import {
    EyeBrowColorObject,
    eyeBrowColor_Objects,
} from "./Models/EyeBrow/color";
import { EyeColorObject, eyeColor_Objects } from "./Models/Eye/color";
import { HexColorPicker } from "react-colorful";
import {
    EyeLashColorObject,
    eyeLashColor_Objects,
} from "./Models/EyeLashes/color";
import { CheekColorObject, cheekColor_Objects } from "./Models/Cheek/color";
//Configured Models In versioned order
import { initialState, initialData } from "./State";
import { Model } from "./Models/Web_Rig/Avatar";
import { ExtraObject, extraObjects } from "./Models/ExtraObjects/color";
import { setLocalStorage } from "../login/helpers/auth";
import { ConstructionOutlined } from "@mui/icons-material";

CameraControls.install({ THREE });
extend({ CameraControls });

//ScrollBar functionalities Start
function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}

//ScrollBar functionalities End
function Controls({
    zoom,
    focus,
    clicked,
    setData,
    focusZoom,
    pos = new THREE.Vector3(),
    look = new THREE.Vector3(),
}) {
    const [activeEl, setActiveEl] = useState(zoom);
    const ref = useRef();
    const camera = useThree((state) => state.camera);
    const gl = useThree((state) => state.gl);

    useEffect(() => {
        ref.current.minDistance = 1.3;
        ref.current.maxDistance = 2.78;
        ref.current.mouseButtons.right = null;
        ref.current.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
        ref.current.touches.three = CameraControls.ACTION.TOUCH_DOLLY;
    }, []);

    useEffect(() => {
        pos.set(focus[zoom].x, focus[zoom].y, focus[zoom].z);
        zoom === 1
            ? look.set(0, 0, 0)
            : look.set(
                  focus[zoom].x,
                  focus[zoom].y,
                  focus[zoom].z - 1.5126069426041975
              );

        camera.position.lerp(pos, 1);

        ref.current.setLookAt(
            camera.position.x,
            camera.position.y,
            camera.position.z,
            look.x,
            look.y,
            look.z,
            true
        );
        setData((prevState) => ({
            ...prevState,
            zoom: !clicked,
        }));
        camera.updateProjectionMatrix();
    }, [activeEl]);

    useFrame((state, delta) => {
        // console.log(camera.position);

        if (clicked) {
            // console.log("EXECUTED!!")
            pos.set(focus[zoom].x, focus[zoom].y, focus[zoom].z);
            zoom === 1
                ? look.set(0, 0, 0)
                : look.set(
                      focus[zoom].x,
                      focus[zoom].y,
                      focus[zoom].z - 1.5126069426041975
                  );

            state.camera.position.lerp(pos, 1);

            ref.current.setLookAt(
                state.camera.position.x,
                state.camera.position.y,
                state.camera.position.z,
                look.x,
                look.y,
                look.z,
                true
            );
            setData((prevState) => ({
                ...prevState,
                zoom: !clicked,
            }));
            state.camera.updateProjectionMatrix();
        }
        if (clicked) {
            // console.log("EXECUTED!!")
            pos.set(focus[zoom].x, focus[zoom].y, focus[zoom].z);
            zoom === 1
                ? look.set(0, 0, 0)
                : look.set(
                      focus[zoom].x,
                      focus[zoom].y,
                      focus[zoom].z - 1.5126069426041975
                  );

            state.camera.position.lerp(pos, 1);

            ref.current.setLookAt(
                state.camera.position.x,
                state.camera.position.y,
                state.camera.position.z,
                look.x,
                look.y,
                look.z,
                true
            );
            setData((prevState) => ({
                ...prevState,
                zoom: !clicked,
            }));
            state.camera.updateProjectionMatrix();
        }
        if (focusZoom) {
            pos.set(focus[0].x, focus[0].y, focus[0].z);
            look.set(focus[0].x, focus[0].y, focus[0].z - 1.5126069426041975);
            state.camera.position.lerp(pos, 1);
            state.camera.updateProjectionMatrix();

            ref.current.setLookAt(
                state.camera.position.x,
                state.camera.position.y,
                state.camera.position.z,
                look.x,
                look.y,
                look.z,
                true
            );
            setData((prevState) => ({
                ...prevState,
                focus: false,
            }));
        }
        ref.current.update(delta);
    });
    return <cameraControls ref={ref} args={[camera, gl.domElement]} />;
}

function Configurator({ history }) {
    const [position, setPosition] = useState(0);
    const { disableScroll, enableScroll } = usePreventBodyScroll();
    const { dragStart, dragStop, dragMove, dragging } = useDrag();
    const [heightChange, setHeightChange] = useState(false);
    const [optionsChange, setOptionsChange] = useState(false);

    const handleDrag =
        ({ scrollContainer }) =>
        (ev) =>
            dragMove(ev, (posDiff) => {
                if (scrollContainer.current) {
                    // console.log(scrollContainer.current);
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });

    const restorePosition = useCallback(
        ({ scrollContainer, getItemById, scrollToItem }) => {},
        [position]
    );

    const savePosition = useCallback(
        throttle(({ scrollContainer }) => {
            !!scrollContainer.current &&
                setPosition(scrollContainer.current.scrollLeft);
        }, 500),
        []
    );

    //ScrollBar functionalities End
    const focusPosition = [
        {
            x: 0.008693305679921756,
            y: 0.5564082766879318,
            z: 1.5126069426041975,
        },

        {
            x: 0.03656103816050099,
            y: 0.39433072541776726,
            z: 1.9317631358907454,
        },
    ];

    //currently set activeGender
    const [activeGender, setActiveGender] = useState(
        localStorage.getItem("gender")
    );

    // Color Functionality Start

    const colorChange = [
        {
            color: "#d6a56f",
        },
        {
            color: "#ab2617",
        },
        {
            color: "#ce312e",
        },
        {
            color: "#d6881d",
        },
        {
            color: "#cea72d",
        },
        {
            color: "#27634e",
        },
        {
            color: "#4c8d8e",
        },
    ];

    const hair_eye_color = [
        {
            color: "#000000",
        },
        {
            color: "#6e4935",
        },
        {
            color: "#ab2617",
        },
        {
            color: "#d6881d",
        },
        {
            color: "#fec702",
        },
        {
            color: "#5b9ad4",
        },
        {
            color: "#4c8d8e",
        },
    ];

    const [data, setData] = useState(
        JSON.parse(localStorage.getItem("data")) || initialData
    );

    const [state, setState, pointer, setPointer] = useStateSnapshots(
        JSON.parse(localStorage.getItem("state")) || initialState,
        1000,
        20
    );

    // Update the localStorage data whenever a user changes their avatar
    useEffect(() => {
        window.localStorage.setItem("data", JSON.stringify(data));
        window.localStorage.setItem("state", JSON.stringify(state));
    }, [data, state]);

    const [clicked, setClicked] = useState(false);
    const [title, setTitle] = useState("Tops");
    const [color, setColor] = useState("#b32aa9");

    useEffect(() => {
        activeGender === "0"
            ? data.activeElement === 0
                ? setTitle(maleFaceOption[data.activeOption])
                : setTitle(maleBodyOption[data.activeOption])
            : data.activeElement === 0
            ? setTitle(femaleFaceOption[data.activeOption])
            : setTitle(femaleBodyOption[data.activeOption]);
    }, [data.activeElement, data.activeOption]);

    const updatedAnimationNames = [
        "walk",
        "stand_up",
        "sit_down",
        "run",
        "jump",
        "dance",
        "T_pose",
        "Idle",
        "Clapping",
        "Waving",
        "Jump_walk",
    ];

    const ClickAnimation = () => {
        setState((prevState) => ({
            ...prevState,
            appliedAnimation:
                (state.appliedAnimation + 1) % updatedAnimationNames.length,
        }));
    };

    const ClickHome = () => history.push("/gender");
    const ClickUndo = () => {
        setPointer(pointer - 1);
    };
    const ClickUpload = () => {};
    const ClickSearch = () => {
        setData((prevState) => ({
            ...prevState,
            focus: !data.focus,
        }));
    };
    const ClickHead = () => {
        setData((prevState) => ({
            ...prevState,
            activeElement: 0,
            activeOption: 0,
            colorActive: false,
            zoom: !data.zoom,
        }));
    };
    const ClickBody = () => {
        setData((prevState) => ({
            ...prevState,
            activeElement: 1,
            activeOption: 0,
            colorActive: false,
            zoom: !data.zoom,
        }));
    };

    //SAVE FUNCTIONALITY - update in DataBase
    const ClickSave = async () => {
        console.log("TRIGGER SAVE LOGIC");
    };

    //update in LocalStorage after done from Server
    const ClickReset = async () => {
        setData(initialData);
        setState(initialState);
        setColor("#b32aa9");
        setTitle("Tops");
    };

    const optionsBar = useRef();
    const titleBar = useRef();
    const exportRef = useRef();
    const app = useRef();

    useEffect(() => {
        //optionsBarHeight
        const optionsBarHeight = optionsBar.current.clientHeight;
        document.documentElement.style.setProperty(
            "--optionsBarHeight",
            `${optionsBarHeight}px`
        );

        //titleBarHeight
        const titleBarHeight = titleBar.current.clientHeight;
        document.documentElement.style.setProperty(
            "--titleBarHeightChange",
            `${titleBarHeight}px`
        );

        const menuTopPadding = titleBarHeight - 60;
        if (titleBarHeight > 60) {
            document.documentElement.style.setProperty(
                "--menuTopPadding",
                `${menuTopPadding}px`
            );
            setHeightChange(true);
        } else {
            setHeightChange(false);
        }

        //appHeight
        const appHeight = app.current.clientHeight;
        const appWidth = app.current.clientWidth;
        document.documentElement.style.setProperty(
            "--appHeight",
            `${appHeight}px`
        );
        // console.log(`${appWidth} x ${appHeight}`);
        if (appHeight < appWidth * 0.48) {
            setOptionsChange(true);
            console.log("SHOULD ADJUST STYLES");
        } else {
            setOptionsChange(false);
        }
    }, []);

    useEffect(() => {
        const handleWindowResize = () => {
            // console.log("RESIZING");
            //optionsBarHeight
            const optionsBarHeight = optionsBar.current.clientHeight;
            document.documentElement.style.setProperty(
                "--optionsBarHeight",
                `${optionsBarHeight}px`
            );

            //titleBarHeight
            const titleBarHeight = titleBar.current.clientHeight;
            document.documentElement.style.setProperty(
                "--titleBarHeightChange",
                `${titleBarHeight}px`
            );

            const menuTopPadding = titleBarHeight - 60;
            if (titleBarHeight > 60) {
                document.documentElement.style.setProperty(
                    "--menuTopPadding",
                    `${menuTopPadding}px`
                );
                setHeightChange(true);
            } else {
                setHeightChange(false);
            }

            //appHeight
            const appHeight = app.current.clientHeight;
            const appWidth = app.current.clientWidth;
            document.documentElement.style.setProperty(
                "--appHeight",
                `${appHeight}px`
            );
            // console.log(`${appWidth} x ${appHeight}`);
            if (appHeight < appWidth * 0.48) {
                setOptionsChange(true);
                // console.log("SHOULD ADJUST STYLES");
            } else setOptionsChange(false);
        };

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const scrollContainerClassName = `${
        data.activeElement === 1 && activeGender === "0"
            ? "flex scrollclass items-center"
            : "flex items-center"
    }`;
    const optionsBarClassName = `objects-bar-child items-end justify-evenly ${
        data.activeOption === 0 &&
        data.customTextureActive &&
        data.activeElement === 1 &&
        activeGender === "1"
            ? "flex flex-col justify-center h-full"
            : "inline-flex flex-wrap"
    }`;

    const titleBarClassName = `${
        heightChange ? "height-update" : ""
    } title-bar flex flex-col justify-center items-center`;
    const colorPickerClassName = `hover:cursor-pointer picker picker-selected ${
        (data.activeOption === 2 &&
            data.colorActive &&
            state.appliedEyeColor === "colorPicker") ||
        (data.activeOption === 2 &&
            data.eyelashColorActive &&
            state.appliedEyeLashColor === "colorPicker") ||
        (data.activeOption === 3 &&
            data.colorActive &&
            data.activeElement === 0 &&
            state.appliedEyeBrowColor === "colorPicker") ||
        ((data.activeOption === 7 || data.activeOption === 8) &&
            data.colorActive &&
            data.activeElement === 0 &&
            state.appliedHairColor === "colorPicker") ||
        (data.activeOption === 6 &&
            data.colorActive &&
            data.activeElement === 0 &&
            state.appliedCheekColor === "colorPicker")
            ? "--is-active"
            : ""
    }`;

    // useEffect(() => {
    //   const appHeight = app.current.clientHeight;
    //   const appWidth = app.current.clientWidth;
    //   document.documentElement.style.setProperty("--appHeight", `${appHeight}px`);
    //   console.log(`${appWidth} x ${appHeight}`);
    //   if (appHeight < appWidth * 0.48) {
    //     setOptionsChange(!optionsChange);
    //     console.log("SHOULD ADJUST STYLES");
    //   }
    // }, []);

    const option_1_ClassName = `${
        optionsChange ? "option_update_1" : ""
    } option-icon_1 icons_test`;
    const option_1_ClassName_VRPANDA = `${
        optionsChange ? "option_update_1" : ""
    } option-icon_1 icons_test vrpanda_icon`;
    const option_2_ClassName = `${
        optionsChange ? "option_update_2" : ""
    } option-icon_2 icons_test`;
    const option_2_ClassName_2 = `${
        optionsChange ? "option_update_2" : ""
    } option-icon_2 icons_test options_after_avatar_2_2`;
    const option_3_ClassName = `${
        optionsChange ? "option_update_3" : ""
    } option-icon_3 icons_test`;
    const optionsClassName = `${optionsChange ? "options_update" : ""} options`;

    return (
        <>
            <div ref={app} className="App">
                <div className="rotate-warning-container">
                    <div className="phone"></div>
                    <div className="message">Please rotate your device!</div>
                </div>
                <div className="main-container">
                    <div className="options-container">
                        <div className={optionsClassName}>
                            <div className={option_1_ClassName}>
                                <HomeButton onClick={ClickHome} />
                            </div>
                            <div className={option_3_ClassName}>
                                <BackButton onClick={ClickUndo} />
                                {/* <UploadButton onClick={ClickUpload} /> */}
                                <SearchButton onClick={ClickSearch} />
                            </div>
                            <div className={option_2_ClassName}>
                                <HeadButton
                                    onClick={ClickHead}
                                    activeElement={data.activeElement}
                                />
                                <BodyButton
                                    onClick={ClickBody}
                                    activeElement={data.activeElement}
                                />
                            </div>
                        </div>
                        <div ref={exportRef} className="avatar">
                            <Suspense fallback={null}>
                                <Canvas
                                    flat
                                    camera={{
                                        fov: 75,
                                        near: 0.01,
                                        far: 1000,
                                        position: [
                                            0.03656103816050099,
                                            0.39433072541776726,
                                            1.9317631358907454,
                                        ],
                                    }}
                                    gl={{ preserveDrawingBuffer: true }}
                                >
                                    <Suspense fallback={null}>
                                        <Suspense fallback={null}>
                                            <Foo
                                                setState={setState}
                                                exportRef={exportRef}
                                                clicked={clicked}
                                                setClicked={setClicked}
                                            />
                                        </Suspense>
                                        <Suspense fallback={null}>
                                            <Lights clicked={clicked} />
                                        </Suspense>
                                        <Suspense fallback={null}>
                                            <Model
                                                state={state}
                                                gender={activeGender}
                                                appliedAnimation={
                                                    state.appliedAnimation
                                                }
                                                activeGender={activeGender}
                                                appliedFace={state.appliedFace}
                                                appliedBeard={
                                                    state.appliedBeard
                                                }
                                                appliedCheek={
                                                    state.appliedCheek
                                                }
                                                appliedEyes={state.appliedEyes}
                                                appliedEyeBrow={
                                                    state.appliedEyeBrow
                                                }
                                                appliedHairColor={
                                                    state.appliedHairColor
                                                }
                                                appliedEyeBrowColor={
                                                    state.appliedEyeBrowColor
                                                }
                                                appliedEyeColor={
                                                    state.appliedEyeColor
                                                }
                                                appliedBoyHairStyle={
                                                    state.appliedBoyHairStyle
                                                }
                                                appliedMouth={
                                                    state.appliedMouth
                                                }
                                                appliedNose={state.appliedNose}
                                                appliedBoyUpperClothes={
                                                    state.appliedBoyUpperClothes
                                                }
                                                appliedBoyLowerClothes={
                                                    state.appliedBoyLowerClothes
                                                }
                                                appliedGirlUpperClothes={
                                                    state.appliedGirlUpperClothes
                                                }
                                                appliedGirlLowerClothes={
                                                    state.appliedGirlLowerClothes
                                                }
                                                appliedGirlFullClothes={
                                                    state.appliedGirlFullClothes
                                                }
                                                appliedGlasses={
                                                    state.appliedGlasses
                                                }
                                                appliedGirlHairStyle={
                                                    state.appliedGirlHairStyle
                                                }
                                                appliedAccessory={
                                                    state.appliedAccessory
                                                }
                                                appliedShoes={
                                                    state.appliedShoes
                                                }
                                                colorBHS={state.colorBHS}
                                                colorGHS={state.colorGHS}
                                                colorBD={state.colorBD}
                                                colorES={state.colorES}
                                                colorCK={state.colorCK}
                                                colorELS={state.colorELS}
                                                colorEBS={state.colorEBS}
                                                appliedBody={state.appliedBody}
                                                appliedLogo={state.appliedLogo}
                                                logoVisible={state.toggleLogo}
                                                clicked={clicked}
                                            />
                                        </Suspense>
                                    </Suspense>
                                    <Controls
                                        zoom={data.activeElement}
                                        focus={focusPosition}
                                        clicked={data.zoom}
                                        setData={setData}
                                        focusZoom={data.focus}
                                    />
                                </Canvas>
                            </Suspense>
                        </div>
                        <div className={optionsClassName}>
                            <div className={option_2_ClassName}>
                                <DownloadButton
                                    onClick={() => {
                                        setData((prevState) => ({
                                            ...prevState,
                                            activeElement: 1,
                                            zoom: !data.zoom,
                                            activeOption: 0,
                                            colorActive: false,
                                        }));
                                        setLocalStorage(
                                            "animation",
                                            state.appliedAnimation
                                        );
                                        setState((prevState) => ({
                                            ...prevState,
                                            appliedAnimation: 1,
                                        }));
                                        setClicked(!clicked);
                                    }}
                                />
                                <SaveButton onClick={ClickSave} />
                            </div>
                            <div className={option_2_ClassName_2}>
                                <AnimationButton
                                    appliedAnimation={state.appliedAnimation}
                                    onClick={ClickAnimation}
                                />
                                <ResetButton onClick={ClickReset} />
                            </div>
                            <div className={option_1_ClassName_VRPANDA}>
                                <VRPanda />
                            </div>
                        </div>
                    </div>

                    <div className="menu">
                        <div className="menu_child">
                            <div ref={titleBar} className={titleBarClassName}>
                                <div className="title font-sans text-3xl text-white ">
                                    {title}
                                </div>
                            </div>
                            <div className="options-bar ">
                                <div
                                    ref={optionsBar}
                                    onMouseEnter={disableScroll}
                                    onMouseLeave={enableScroll}
                                >
                                    <ScrollMenu
                                        LeftArrow={LeftArrow}
                                        RightArrow={RightArrow}
                                        onInit={restorePosition}
                                        onScroll={savePosition}
                                        onWheel={onWheel}
                                        onMouseDown={() => dragStart}
                                        onMouseUp={() => dragStop}
                                        onMouseMove={handleDrag}
                                        scrollContainerClassName={
                                            scrollContainerClassName
                                        }
                                    >
                                        {activeGender === "0" &&
                                            data.activeElement === 0 &&
                                            boyFaceInactive.map(
                                                (options, index) => (
                                                    <BoyOption
                                                        key={index}
                                                        id={index}
                                                        options={options}
                                                        active={
                                                            data.activeOption
                                                        }
                                                        activeColor={
                                                            data.colorActive
                                                        }
                                                        eyelashColor={
                                                            data.eyelashColorActive
                                                        }
                                                        onClick={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    activeOption:
                                                                        index,
                                                                    colorActive: false,
                                                                    eyelashColorActive: false,
                                                                })
                                                            );
                                                            setTitle(
                                                                maleFaceOption[
                                                                    index
                                                                ]
                                                            );
                                                        }}
                                                        colorChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    eyelashColorActive: false,
                                                                    colorActive: true,
                                                                })
                                                            );
                                                        }}
                                                        eyelashColorChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    colorActive: false,
                                                                    eyelashColorActive: true,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )
                                            )}
                                        {activeGender === "0" &&
                                            data.activeElement === 1 &&
                                            boyBodyInactive.map(
                                                (options, index) => (
                                                    <BoyOption
                                                        key={index}
                                                        id={index}
                                                        options={options}
                                                        active={
                                                            data.activeOption
                                                        }
                                                        onClick={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    activeOption:
                                                                        index,
                                                                    customTopActive: false,
                                                                    customTextureActive: false,
                                                                })
                                                            );
                                                            setTitle(
                                                                maleBodyOption[
                                                                    index
                                                                ]
                                                            );
                                                        }}
                                                        customTop={
                                                            data.customTopActive
                                                        }
                                                        customTexture={
                                                            data.customTextureActive
                                                        }
                                                        textureChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    customTextureActive: true,
                                                                    customTopActive: false,
                                                                })
                                                            );
                                                            // setState((prevState) => ({
                                                            //   ...prevState,
                                                            //   appliedBoyUpperClothes: "CBUC",
                                                            // }));
                                                        }}
                                                        customTopChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    customTopActive: true,
                                                                    customTextureActive: false,
                                                                })
                                                            );
                                                            // setState((prevState) => ({
                                                            //   ...prevState,
                                                            //   appliedBoyUpperClothes: "CBUC",
                                                            // }));
                                                        }}
                                                    />
                                                )
                                            )}

                                        {activeGender === "1" &&
                                            data.activeElement === 0 &&
                                            girlFaceInactive.map(
                                                (options, index) => (
                                                    <GirlOption
                                                        key={index}
                                                        id={index}
                                                        options={options}
                                                        active={
                                                            data.activeOption
                                                        }
                                                        activeColor={
                                                            data.colorActive
                                                        }
                                                        eyelashColor={
                                                            data.eyelashColorActive
                                                        }
                                                        onClick={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    activeOption:
                                                                        index,
                                                                    colorActive: false,
                                                                    eyelashColorActive: false,
                                                                })
                                                            );
                                                            setTitle(
                                                                femaleFaceOption[
                                                                    index
                                                                ]
                                                            );
                                                        }}
                                                        colorChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    eyelashColorActive: false,
                                                                    colorActive: true,
                                                                })
                                                            );
                                                        }}
                                                        eyelashColorChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    colorActive: false,
                                                                    eyelashColorActive: true,
                                                                })
                                                            );
                                                        }}
                                                    />
                                                )
                                            )}

                                        {activeGender === "1" &&
                                            data.activeElement === 1 &&
                                            girlBodyInactive.map(
                                                (options, index) => (
                                                    <GirlOption
                                                        key={index}
                                                        id={index}
                                                        options={options}
                                                        active={
                                                            data.activeOption
                                                        }
                                                        onClick={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    activeOption:
                                                                        index,
                                                                    customTopActive: false,
                                                                    customTextureActive: false,
                                                                })
                                                            );
                                                            setTitle(
                                                                femaleBodyOption[
                                                                    index
                                                                ]
                                                            );
                                                        }}
                                                        customTop={
                                                            data.customTopActive
                                                        }
                                                        customTexture={
                                                            data.customTextureActive
                                                        }
                                                        textureChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    customTextureActive: true,
                                                                    customTopActive: false,
                                                                })
                                                            );
                                                            // setState((prevState) => ({
                                                            //   ...prevState,
                                                            //   appliedGirlFullClothes: "NULL",
                                                            //   appliedGirlUpperClothes: "CGUC",
                                                            // }));
                                                        }}
                                                        customTopChange={() => {
                                                            setData(
                                                                (
                                                                    prevState
                                                                ) => ({
                                                                    ...prevState,
                                                                    customTopActive: true,
                                                                    customTextureActive: false,
                                                                })
                                                            );
                                                            // setState((prevState) => ({
                                                            //   ...prevState,
                                                            //   appliedGirlFullClothes: "NULL",
                                                            //   appliedGirlUpperClothes: "CGUC",
                                                            // }));
                                                        }}
                                                    />
                                                )
                                            )}
                                    </ScrollMenu>
                                </div>
                            </div>
                            <div className="objects-bar ">
                                <div
                                    className={optionsBarClassName}
                                    id="objects-bar-div"
                                >
                                    {data.activeOption === 0 &&
                                        data.activeElement === 0 &&
                                        face_Objects.map((options, index) => (
                                            <FaceObject
                                                key={index}
                                                id={index}
                                                active={state.appliedFace}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedFace:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 1 &&
                                        data.activeElement === 0 &&
                                        bodyColor_Objects.map(
                                            (options, index) => (
                                                <BodyColorObject
                                                    key={index}
                                                    id={index}
                                                    active={state.appliedBody}
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorBD: index,
                                                                appliedBody:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 2 &&
                                        !data.colorActive &&
                                        !data.eyelashColorActive &&
                                        data.activeElement === 0 &&
                                        eyes_Objects.map((options, index) => (
                                            <EyesObject
                                                key={index}
                                                id={index}
                                                active={state.appliedEyes}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedEyes:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 2 &&
                                        data.colorActive &&
                                        data.activeElement === 0 &&
                                        eyeColor_Objects.map(
                                            (options, index) => (
                                                <EyeColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedEyeColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorES:
                                                                    hair_eye_color[
                                                                        index
                                                                    ].color,
                                                                appliedEyeColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 2 &&
                                        data.eyelashColorActive &&
                                        data.activeElement === 0 &&
                                        eyeLashColor_Objects.map(
                                            (options, index) => (
                                                <EyeLashColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedEyeLashColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorELS:
                                                                    colorChange[
                                                                        index
                                                                    ].color,
                                                                appliedEyeLashColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 3 &&
                                        !data.colorActive &&
                                        data.activeElement === 0 &&
                                        eyeBrow_Objects.map(
                                            (options, index) => (
                                                <EyeBrowObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedEyeBrow
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedEyeBrow:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 3 &&
                                        data.colorActive &&
                                        data.activeElement === 0 &&
                                        eyeBrowColor_Objects.map(
                                            (options, index) => (
                                                <EyeBrowColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedEyeBrowColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorEBS:
                                                                    colorChange[
                                                                        index
                                                                    ].color,
                                                                appliedEyeBrowColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 4 &&
                                        data.activeElement === 0 &&
                                        mouth_Objects.map((options, index) => (
                                            <MouthObject
                                                key={index}
                                                id={index}
                                                active={state.appliedMouth}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedMouth:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 5 &&
                                        data.activeElement === 0 &&
                                        nose_Objects.map((options, index) => (
                                            <NoseObject
                                                key={index}
                                                id={index}
                                                active={state.appliedNose}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedNose:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 6 &&
                                        !data.colorActive &&
                                        data.activeElement === 0 &&
                                        cheek_Objects.map((options, index) => (
                                            <CheekObject
                                                key={index}
                                                id={index}
                                                active={state.appliedCheek}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedCheek:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 6 &&
                                        data.colorActive &&
                                        data.activeElement === 0 &&
                                        cheekColor_Objects.map(
                                            (options, index) => (
                                                <CheekColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedCheekColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorCK:
                                                                    colorChange[
                                                                        index
                                                                    ].color,
                                                                appliedCheekColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 7 &&
                                        data.activeElement === 0 &&
                                        activeGender === "0" &&
                                        beard_Objects.map((options, index) => (
                                            <BeardObject
                                                key={index}
                                                id={index}
                                                active={state.appliedBeard}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedBeard:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 7 &&
                                        !data.colorActive &&
                                        data.activeElement === 0 &&
                                        activeGender === "1" &&
                                        girlHairStyle_Objects.map(
                                            (options, index) => (
                                                <GirlHairStyleObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGirlHairStyle
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGirlHairStyle:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 7 &&
                                        data.colorActive &&
                                        data.activeElement === 0 &&
                                        activeGender === "1" &&
                                        girlHairColor_Objects.map(
                                            (options, index) => (
                                                <GirlHairColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedHairColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorGHS:
                                                                    hair_eye_color[
                                                                        index
                                                                    ].color,
                                                                appliedHairColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 8 &&
                                        !data.colorActive &&
                                        data.activeElement === 0 &&
                                        activeGender === "0" &&
                                        boyHairStyle_Objects.map(
                                            (options, index) => (
                                                <BoyHairStyleObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedBoyHairStyle
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedBoyHairStyle:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 8 &&
                                        data.colorActive &&
                                        data.activeElement === 0 &&
                                        activeGender === "0" &&
                                        boyHairColor_Objects.map(
                                            (options, index) => (
                                                <BoyHairColorObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedHairColor
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorBHS:
                                                                    hair_eye_color[
                                                                        index
                                                                    ].color,
                                                                appliedHairColor:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 9 &&
                                        data.activeElement === 0 &&
                                        activeGender === "0" &&
                                        glasses_Objects.map(
                                            (options, index) => (
                                                <GlassObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGlasses
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGlasses:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 8 &&
                                        data.activeElement === 0 &&
                                        activeGender === "1" &&
                                        glasses_Objects.map(
                                            (options, index) => (
                                                <GlassObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGlasses
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGlasses:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 0 &&
                                        !data.customTopActive &&
                                        !data.customTextureActive &&
                                        data.activeElement === 1 &&
                                        activeGender === "1" &&
                                        girlUpperClothes_Objects.map(
                                            (options, index) => (
                                                <GirlUpperClothObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGirlUpperClothes
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGirlFullClothes:
                                                                    "NULL",
                                                                appliedGirlUpperClothes:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 0 &&
                                        !data.customTopActive &&
                                        !data.customTextureActive &&
                                        data.activeElement === 1 &&
                                        activeGender === "0" &&
                                        boyUpperClothes_Objects.map(
                                            (options, index) => (
                                                <BoyUpperClothObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedBoyUpperClothes
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedBoyUpperClothes:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 1 &&
                                        data.activeElement === 1 &&
                                        activeGender === "1" &&
                                        girlLowerClothes_Objects.map(
                                            (options, index) => (
                                                <GirlLowerClothObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGirlLowerClothes
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGirlFullClothes:
                                                                    "NULL",
                                                                appliedGirlLowerClothes:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 1 &&
                                        data.activeElement === 1 &&
                                        activeGender === "0" &&
                                        boyLowerClothes_Objects.map(
                                            (options, index) => (
                                                <BoyLowerClothObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedBoyLowerClothes
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedBoyLowerClothes:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 2 &&
                                        data.activeElement === 1 &&
                                        activeGender === "1" &&
                                        girlFullClothes_Objects.map(
                                            (options, index) => (
                                                <GirlFullClothObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedGirlFullClothes
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedGirlFullClothes:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 2 &&
                                        data.activeElement === 1 &&
                                        activeGender === "0" &&
                                        shoes_Objects.map((options, index) => (
                                            <ShoeObject
                                                key={index}
                                                id={index}
                                                active={state.appliedShoes}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedShoes:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 3 &&
                                        data.activeElement === 1 &&
                                        activeGender === "1" &&
                                        shoes_Objects.map((options, index) => (
                                            <ShoeObject
                                                key={index}
                                                id={index}
                                                active={state.appliedShoes}
                                                options={options}
                                                onClick={() => {
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        appliedShoes:
                                                            options.alt_text,
                                                    }));
                                                }}
                                            />
                                        ))}

                                    {data.activeOption === 3 &&
                                        data.activeElement === 1 &&
                                        activeGender === "0" &&
                                        accessory_Objects.map(
                                            (options, index) => (
                                                <AccessoryObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedAccessory
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedAccessory:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {data.activeOption === 4 &&
                                        data.activeElement === 1 &&
                                        activeGender === "1" &&
                                        accessory_Objects.map(
                                            (options, index) => (
                                                <AccessoryObject
                                                    key={index}
                                                    id={index}
                                                    active={
                                                        state.appliedAccessory
                                                    }
                                                    options={options}
                                                    onClick={() => {
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                appliedAccessory:
                                                                    options.alt_text,
                                                            })
                                                        );
                                                    }}
                                                />
                                            )
                                        )}

                                    {((data.activeElement === 0 &&
                                        data.colorActive) ||
                                        (data.activeElement === 0 &&
                                            data.eyelashColorActive)) && (
                                        <div>
                                            <HexColorPicker
                                                className={colorPickerClassName}
                                                color={color}
                                                onChange={(color) => {
                                                    if (data.activeOption === 1)
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorBD: color,
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption ===
                                                            2 &&
                                                        data.colorActive
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorES: color,
                                                                appliedEyeColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption ===
                                                            2 &&
                                                        data.eyelashColorActive
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorELS: color,
                                                                appliedEyeLashColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption === 3
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorEBS: color,
                                                                appliedEyeBrowColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption === 6
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorCK: color,
                                                                appliedCheekColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption === 7
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorGHS: color,
                                                                appliedHairColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                    else if (
                                                        data.activeOption === 8
                                                    )
                                                        setState(
                                                            (prevState) => ({
                                                                ...prevState,
                                                                colorBHS: color,
                                                                appliedHairColor:
                                                                    "colorPicker",
                                                            })
                                                        );
                                                }}
                                            />
                                        </div>
                                    )}

                                    {((data.activeElement === 0 &&
                                        data.colorActive) ||
                                        (data.activeElement === 0 &&
                                            data.eyelashColorActive)) &&
                                        extraObjects.map((options, index) => (
                                            <ExtraObject
                                                key={index}
                                                id={index}
                                                options={options}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Loading />
        </>
    );
}

export default Configurator;
