/* eslint-disable */
import { useState, useEffect } from "react";
import { setGender } from "../login/helpers/auth";
import { ToastContainer, toast } from "react-toastify";
import "../configurator/App.css"

function LandingPage({ history, username }) {
  const [activeOption, setActiveOption] = useState(null);

  const setMale = (value) => {
    setGender(value);
  };

  const setFemale = (value) => {
    setGender(value);
  };

  useEffect(() => {
    setTimeout(() => {
      !(username === undefined) && toast.success(`Hey ${username}, Proceed!`);
    }, 1000);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    activeOption === null
      ? toast.error(`Please select your gender😁`)
      : history.push("/configurator");
  };

  return (
    <div className="min-h-screen bg-gray-100 text-gray-900 flex flex-col justify-center">
      <ToastContainer />
      <div className="max-w-screen-[100vw-24] m-6 bg-white  rounded-lg flex justify-center flex-1">
        <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 flex justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl xl:text-3xl font-extrabold log-in-text">
              Choose your gender
            </h1>
            <div className="w-full flex-1 mt-8 text-indigo-500">
              <div className="w-full flex flex-col items-center">
                <button
                  className={`mb-6 mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                    activeOption === 0
                      ? "bg-indigo-900 border-amber-500 border-4"
                      : "bg-indigo-500"
                  }`}
                  onClick={() => {
                    setMale(0);
                    setActiveOption(0);
                  }}
                >
                  <i className="fa fa-male   fa-2x w-6  -ml-2" />
                  <span className="ml-3">Male</span>
                </button>
                <button
                  className={`mb-6 mt-5 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full py-4 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none ${
                    activeOption === 1
                      ? "bg-indigo-900 border-amber-500 border-4"
                      : "bg-indigo-500"
                  }`}
                  onClick={() => {
                    setFemale(1);
                    setActiveOption(1);
                  }}
                >
                  <i className="fa fa-female  fa-2x w-6  -ml-2" />
                  <span className="ml-3">Female</span>
                </button>
                <div className="w-full flex flex-col items-center hover:cursor-pointer">
                  <a
                    onClick={handleClick}
                    className="w-full max-w-xs font-bold shadow-sm rounded-lg py-3
         bg-indigo-100 text-gray-800 flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline mt-5"
                    target="_self"
                  >
                    <i className="fas fa-sign-in-alt fa-2x  1x w-6  -ml-2 text-indigo-500" />
                    <span className="ml-4 log-in-text">Continue</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
