import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// import { Buffer } from "buffer";
import React, {useState } from "react";
import {Login} from "./components/login/screens/Login";
import Register from "./components/login/screens/Register";
import Activate from "./components/login/screens/Activate";
import Private from "./components/login/screens/Private";
import Admin from "./components/login/screens/Admin";
import ForgetPassword from "./components/login/screens/ForgetPassword";
import ResetPassword from "./components/login/screens/ResetPassword";
import PrivateRoute from "./components/login/Routes/PrivateRoute";
import AdminRoute from "./components/login/Routes/AdminRoute";
import ConfiguratorRoute from "./components/login/Routes/ConfiguratorRoute";
import "react-toastify/dist/ReactToastify.css";
import Configurator from "./components/configurator";
import GenderPage from "./components/gender";

const  App = () =>{
  const [username,setUserName] =  useState();
  return (
    <BrowserRouter>
         <Switch>
           <Route path="/" exact render={(props) => <Login {...props} username={username} setUserName={setUserName}/>} />
           <Route path="/login" exact render={(props) => <Login {...props} username={username} setUserName={setUserName}/>} />
           <Route
             path="/register"
             exact
             render={(props) => <Register {...props} />}
           />
           <Route
             path="/users/password/forget"
             exact
             render={(props) => <ForgetPassword {...props} />}
           />
           <Route
             path="/users/password/reset/:token"
             exact
             render={(props) => <ResetPassword {...props} />}
           />
           <Route
             path="/users/activate/:token"
             exact
             render={(props) => <Activate {...props} />}
           />
           <Route
             path="/gender"
             exact
             render={(props) => <GenderPage {...props} username={username}/>}
           />
 
           <PrivateRoute path="/private" exact component={Private} />
           <AdminRoute path="/admin" exact component={Admin} />
           <ConfiguratorRoute
             path="/configurator"
             exact
             component={Configurator}
           />
           <Redirect to="/" />
         </Switch>
       </BrowserRouter>
     );
}
export default App;


