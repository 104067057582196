import "../../index.css";
import "../../../../index.css";


export const extraObjects = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden "
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden "
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
]

export const ExtraObject = ({options}) => {
  return (
    <>
      <div id={options.id} className="hover:cursor-pointer w-20" >
        {options.icon}
      </div>
    </>
  );
};
