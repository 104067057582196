import "../../index.css";
import "../../../../index.css";

export const bodyColor_Objects = [
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#f5e3cb"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor1",
    alt_text: "BD1",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#f2d3b7"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    alt_text: "BD2",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#f2d2ac"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor3",
    alt_text: "BD3",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#d6ab8b"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor4",
    alt_text: "BD4",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#c38a5f"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor5",
    alt_text: "BD5",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#966640"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor6",
    alt_text: "BD6",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#6e4229"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "bodycolor7",
    alt_text: "BD7",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden "
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
];

export const BodyColorObject = ({ options, active, id, onClick }) => {
  const className = `hover:cursor-pointer w-20 ${
    options.alt_text !== "extra" ? "p-2 h-20" : ""
  } ${options.alt_text === active ? "object-selected" : ""}`;
  return (
    <>
      <div id={options.id} className={className} onClick={onClick}>
        {options.icon}
      </div>
    </>
  );
};
