import "../../index.css";
import "../../../../index.css";

export const eyeColor_Objects = [
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#000000"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor1",
    alt_text: "CK1",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#6e4935"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor2",
    alt_text: "CK2",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#ab2617"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor3",
    alt_text: "CK3",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#d6881d"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor4",
    alt_text: "CK4",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#fec702"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor5",
    alt_text: "CK5",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#5b9ad4"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor6",
    alt_text: "CK6",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#4c8d8e"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "cheekcolor7",
    alt_text: "CK7",
  },

];

export const extraObjects = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden "
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xlmnsxlink="http://www.w3.org/1999/xlink"
        className="hidden"
        viewBox="0 0 101.74 101.74"
      ></svg>
    ),
    id: "invisible",
    alt_text: "extra",
  },
]

export const EyeColorObject = ({ options, active, id, onClick }) => {
  const className = `hover:cursor-pointer w-20 ${
    options.alt_text !== "extra" ? "p-2 h-20" : ""
  } ${options.alt_text === active ? "object-selected" : ""}`;
  return (
    <>
      <div id={options.id} className={className} onClick={onClick}>
        {options.icon}
      </div>
    </>
  );
};
