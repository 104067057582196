import { useMemo } from "react";

export default function Lights() {
  const lights = useMemo(() => {
    return (
      <>
        <>
          <ambientLight intensity={0.5} />
          <pointLight intensity={0.4} position={[10, 15, 10]} />
          <pointLight intensity={0.4} position={[10, 15, -10]} />
          <pointLight intensity={0.4} position={[-10, 15, -10]} />
        </>
      </>
    );
  }, []);

  return <>{lights}</>;
}


