import "../../../index.css";
import "../../../../../index.css";

export const boyHairColor_Objects = [
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#000000"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor1",
    alt_text: "BHS1",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#6e4935"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor2",
    alt_text: "BHS2",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#ab2617"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor3",
    alt_text: "BHS3",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#d6881d"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor4",
    alt_text: "BHS4",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#fec702"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor5",
    alt_text: "BHS5",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#5b9ad4"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor6",
    alt_text: "BHS6",
  },
  {
    icon: (
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        className="--is-active w-full h-full"
        viewBox="0 0 101.74 101.74"
      >
        <g id="Layer_2-2" data-name="Layer 2">
          <rect
            fill="#4c8d8e"
            width="101.74"
            height="101.74"
            rx="16.76"
            ry="16.76"
          />
        </g>
      </svg>
    ),
    id: "boyhaircolor7",
    alt_text: "BHS7",
  },
];

export const BoyHairColorObject = ({ options, active, id, onClick }) => {
  const className = `hover:cursor-pointer w-20 ${
    options.alt_text !== "extra" ? "p-2 h-20" : ""
  } ${options.alt_text === active ? "object-selected" : ""}`;
  return (
    <>
      <div id={options.id} className={className} onClick={onClick}>
      {options.icon}
      </div>
    </>
  );
};