export const initialState = {
    appliedFace: "F1",
    appliedBeard: "BE2",
    appliedCheek: "CK9",
    appliedEyes: "ES7",
    appliedEyeBrow: "EBS1",
    appliedHairColor: "BHS1",
    appliedEyeBrowColor: null,
    appliedEyeColor: null,
    appliedEyeLashColor: null,
    appliedCheekColor: null,
    appliedBoyHairStyle: "BHS2",
    appliedMouth: "MH6",
    appliedNose: "NE1",
    appliedBoyUpperClothes: "BUCS9",
    appliedBoyLowerClothes: "BLCS6",
    appliedGirlUpperClothes: "GUCS5",
    appliedGirlLowerClothes: "GLCS5",
    appliedGirlFullClothes: "NULL",
    appliedGlasses: "DISABLE_GLS",
    appliedGirlHairStyle: "GHS10",
    appliedAccessory: "DISABLE_AS",
    appliedShoes: "SH4",
    appliedAnimation: 0,
    colorGHS: "#6e4935",
    colorBHS: "#8b623a",
    colorBD: "#000000",
    colorES: "#000000",
    colorCK: "#ffffff",
    colorELS: "#000000",
    colorEBS: "#40352a",
    appliedBody: "BD3",
    toggleLogo: null,
    appliedLogo: "LOGO1",
};

export const defaultState = {
    appliedFace: "F1",
    appliedBeard: "BE2",
    appliedCheek: "CK9",
    appliedEyes: "ES7",
    appliedEyeBrow: "EBS1",
    appliedHairColor: "BHS1",
    appliedEyeBrowColor: null,
    appliedEyeColor: null,
    appliedEyeLashColor: null,
    appliedCheekColor: null,
    appliedBoyHairStyle: "BHS2",
    appliedMouth: "MH6",
    appliedNose: "NE1",
    appliedBoyUpperClothes: "BUCS9",
    appliedBoyLowerClothes: "BLCS6",
    appliedGirlUpperClothes: "GUCS5",
    appliedGirlLowerClothes: "GLCS5",
    appliedGirlFullClothes: "NULL",
    appliedGlasses: "DISABLE_GLS",
    appliedGirlHairStyle: "GHS10",
    appliedAccessory: "DISABLE_AS",
    appliedShoes: "SH4",
    appliedAnimation: 0,
    colorGHS: "#6e4935",
    colorBHS: "#8b623a",
    colorBD: "#000000",
    colorES: "#000000",
    colorCK: "#ffffff",
    colorELS: "#000000",
    colorEBS: "#40352a",
    toggleLogo: null,
    appliedBody: "BD3",
};

export const initialData = {
    activeOption: 0,
    // faceActiveOption:0,
    // bodyActiveOption:0,
    colorActive: false,
    eyelashColorActive: false,
    customTopActive: false,
    customTextureActive: false,
    activeElement: 1,
    zoom: false,
    focus: false,
};
