/* eslint-disable */
import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { useAnimations } from "@react-three/drei";
import { TextureLoader } from "three";
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "../../Exporter/GLTFLoader";

let animationClips;
function Model(props) {
  const { scene } = useThree();
  const avatar = "avatar";
  const {
    state,
    gender,
    appliedAnimation,
    activeGender,
    appliedFace,
    appliedBeard,
    appliedCheek,
    appliedEyes,
    appliedEyeBrow,
    appliedBoyHairStyle,
    appliedMouth,
    appliedNose,
    appliedBoyUpperClothes,
    appliedBoyLowerClothes,
    appliedGirlUpperClothes,
    appliedGirlLowerClothes,
    appliedGirlFullClothes,
    appliedGlasses,
    appliedGirlHairStyle,
    appliedAccessory,
    appliedShoes,
    colorBHS,
    colorGHS,
    colorBD,
    colorES,
    colorCK,
    colorELS,
    colorEBS,
    appliedLogo,
    logoVisible,
  } = props;

  const avatarConfig = JSON.stringify({
    token: process.env.REACT_APP_GLTF_EXPORT_TOKEN,
    avatar: state,
    gender: parseInt(gender, 10),
  });

  const textureUrl = [
    "/Skin_Texture/Body_Nose_Tone_1.png",
    "/Skin_Texture/Body_Nose_Tone_2.png",
    "/Skin_Texture/Body_Nose_Tone_3.png",
    "/Skin_Texture/Body_Nose_Tone_4.png",
    "/Skin_Texture/Body_Nose_Tone_5.png",
    "/Skin_Texture/Body_Nose_Tone_6.png",
    "/Skin_Texture/Body_Nose_Tone_7.png",
  ];

  const group = useRef();
  const previousAction = usePrevious(appliedAnimation);
  const { nodes, materials, animations } = useLoader(
    GLTFLoader,
    "/Models/Web_Rig/avatar.glb"
  );
  const cheeksObjects = [
    { selected: "CK1", object: nodes["Cheeks_1"] },
    { selected: "CK2", object: nodes["Cheeks_2"] },
    { selected: "CK3", object: nodes["Cheeks_3"] },
    { selected: "CK4", object: nodes["Cheeks_4"] },
    { selected: "CK5", object: nodes["Cheeks_5"] },
    { selected: "CK6", object: nodes["Cheeks_6"] },
    { selected: "CK7", object: nodes["Cheeks_7"] },
    { selected: "CK8", object: nodes["Cheeks_8"] },
    { selected: "CK9", object: nodes["Cheeks_9"] },
    { selected: "CK10", object: nodes["Cheeks_10"] },
  ];
  cheeksObjects.forEach((f) => (f.object.material.transparent = true));

  const new_upper_cloth1 = useRef();
  const new_upper_cloth2 = useRef();
  const new_upper_cloth3 = useRef();
  const new_upper_cloth4 = useRef();
  const new_upper_cloth5 = useRef();
  const new_lower_cloth1 = useRef();

  const { actions, names } = useAnimations(animations, group);
  animationClips = animations;

  useEffect(() => {
    actions[names[previousAction]]?.fadeOut(0.5);
    actions[names[appliedAnimation]].reset().fadeIn(0.5).play();
  }, [actions, appliedAnimation, previousAction]);

  useEffect(() => {
    scene.traverse((o) => (o.frustumCulled = false));
  });

  const skinToneMaterial = nodes["Body_Geo"].material;
  useEffect(() => {
    const textureLoader = new TextureLoader();
    textureLoader.load(textureUrl[colorBD], (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      texture.flipY = false;
      skinToneMaterial.map = texture;
      skinToneMaterial.needsUpdate = true;
    });
  }, [colorBD]);

  const applyColor = (f, hexColor) => {
    if (hexColor !== null && f !== undefined) {
      let hex2rgb = (c) => `rgb(${c.match(/\w\w/g).map((x) => +`0x${x}`)})`;
      const rgbColor = new THREE.Color(hex2rgb(hexColor));
      f.object.material.color.set(rgbColor);
    }
  };

  const eyeBrowObjects = [
    { selected: "EBS1", object: nodes["upward"] },
    { selected: "EBS2", object: nodes["straight"] },
    { selected: "EBS3", object: nodes["steep_arch"] },
    { selected: "EBS4", object: nodes["S_shaped"] },
    { selected: "EBS5", object: nodes["ROUNDED"] },
    { selected: "EBS6", object: nodes["Arched1"] },
  ];

  useEffect(() => {
    eyeBrowObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedEyeBrow) {
        f.object.visible = true;
        applyColor(f, colorEBS);
      } else f.object.visible = false;
    });
  }, [appliedEyeBrow, appliedFace, colorEBS]);

  useEffect(() => {
    cheeksObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedCheek) {
        f.object.visible = true;
        applyColor(f, colorCK);
      } else f.object.visible = false;
    });
  }, [appliedCheek, appliedFace, colorCK]);

  const eyelashesObjects = [
    { selected: "ES1", object: nodes["Eyelash_1"] },
    { selected: "ES2", object: nodes["Eyelash_2"] },
    { selected: "ES3", object: nodes["Eyelash_3"] },
    { selected: "ES4", object: nodes["Eyelash_4"] },
    { selected: "ES5", object: nodes["Eyelash_5"] },
    { selected: "ES6", object: nodes["Eyelash_6"] },
    { selected: "ES7", object: nodes["Eyelash_7"] },
    { selected: "ES8", object: nodes["Eyelash_8"] },
  ];

  useEffect(() => {
    eyelashesObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedEyes) {
        f.object.visible = true;
        applyColor(f, colorELS);
      } else f.object.visible = false;
    });
  }, [appliedEyes, appliedFace, colorELS]);

  const eyeSkinObjects = [
    { selected: "ES1", object: nodes["_Eye_1FBXASC032Skin"] },
    { selected: "ES6", object: nodes["_Eye_6FBXASC032Skin"] },
    { selected: "ES8", object: nodes["_Eye_8FBXASC032Skin"] },
  ];

  const eyePupilObjects = [
    { selected: "ES1", object: nodes["_Eye_1FBXASC032Pupil"] },
    { selected: "ES2", object: nodes["_Eye_2FBXASC032Pupil"] },
    { selected: "ES3", object: nodes["_Eye_3FBXASC032Pupil"] },
    { selected: "ES4", object: nodes["_Eye_4FBXASC032Pupil"] },
    { selected: "ES5", object: nodes["_Eye_5FBXASC032Pupil"] },
    { selected: "ES6", object: nodes["_Eye_6FBXASC032Pupil"] },
    { selected: "ES7", object: nodes["_Eye_7FBXASC032Pupil"] },
    { selected: "ES8", object: nodes["_Eye_8FBXASC032Pupil"] },
  ];
  const eyeWhiteObjects = [
    { selected: "ES1", object: nodes["_Eye_1FBXASC032White"] },
    { selected: "ES2", object: nodes["_Eye_2FBXASC032White"] },
    { selected: "ES3", object: nodes["_Eye_3FBXASC032White"] },
    { selected: "ES4", object: nodes["_Eye_4FBXASC032White"] },
    { selected: "ES6", object: nodes["_Eye_6FBXASC032White"] },
    { selected: "ES7", object: nodes["_Eye_7FBXASC032White"] },
    { selected: "ES8", object: nodes["_Eye_8FBXASC032White"] },
  ];

  useEffect(() => {
    eyePupilObjects.forEach((e) => {
      if (appliedFace === "F1" && e.selected === appliedEyes) {
        applyColor(e, colorES);
        e.object.visible = true;
      } else e.object.visible = false;
    });
    eyeSkinObjects.forEach((s) => {
      if (appliedFace === "F1" && s.selected === appliedEyes)
        s.object.visible = true;
      else s.object.visible = false;
    });
    eyeWhiteObjects.forEach((w) => {
      if (appliedFace === "F1" && w.selected === appliedEyes)
        w.object.visible = true;
      else w.object.visible = false;
    });
  }, [appliedEyes, appliedFace, colorES]);

  const animalEyeObjects = [
    nodes["Dog_GeoFBXASC032Eyes"],
    nodes["elephantFBXASC032Eyes"],
    nodes["Cow_Eyes"],
    nodes["PigFBXASC032Eyes"],
    nodes["Panda_2"],
  ];

  const faceObjects = [
    { selected: "F1", object: nodes["Head_Geo"] },
    { selected: "F2", object: nodes["Dog_Geo"] },
    { selected: "F2", object: nodes["Dog_GeoFBXASC032Eyes"] },
    { selected: "F3", object: nodes["elephant"] },
    { selected: "F3", object: nodes["elephantFBXASC032Eyes"] },
    { selected: "F4", object: nodes["Cow_Geo"] },
    { selected: "F4", object: nodes["Cow_Eyes"] },
    { selected: "F5", object: nodes["Panda_1"] },
    { selected: "F5", object: nodes["Panda_2"] },
    { selected: "F6", object: nodes["Pig"] },
    { selected: "F6", object: nodes["PigFBXASC032Eyes"] },
  ];

  useEffect(() => {
    faceObjects.forEach((f) => {
      if (f.selected === appliedFace) f.object.visible = true;
      else f.object.visible = false;
    });
  }, [appliedFace, colorBD]);

  const girlHairObjects = [
    { selected: "GHS1", object: nodes["GirlHair1"] },
    { selected: "GHS2", object: nodes["GirlHair2"] },
    { selected: "GHS3", object: nodes["GirlHair3"] },
    { selected: "GHS4", object: nodes["GirlHair4"] },
    { selected: "GHS5", object: nodes["GirlHair5"] },
    { selected: "GHS6", object: nodes["GirlHair6"] },
    { selected: "GHS7", object: nodes["GirlHair7"] },
    { selected: "GHS8", object: nodes["GirlHair8"] },
    { selected: "GHS9", object: nodes["GirlHair9"] },
    { selected: "GHS10", object: nodes["GirlHair10"] },
    { selected: "GHS11", object: nodes["GirlHair11"] },
    { selected: "GHS12", object: nodes["GirlHair12"] },
  ];

  useEffect(() => {
    girlHairObjects.forEach((f) => {
      if (
        activeGender === "1" &&
        appliedFace === "F1" &&
        f.selected === appliedGirlHairStyle
      ) {
        f.object.visible = true;
        applyColor(f, colorGHS);
      } else f.object.visible = false;
    });
  }, [activeGender, appliedGirlHairStyle, appliedFace, colorGHS]);

  const beardObjects = [
    { selected: "BE1", object: nodes["Beard_1"] },
    { selected: "BE2", object: nodes["Beard_2"] },
    { selected: "BE3", object: nodes["Beard_3"] },
    { selected: "BE4", object: nodes["Beard_4"] },
    { selected: "BE5", object: nodes["Beard_5"] },
    { selected: "BE6", object: nodes["Beard_6"] },
    { selected: "BE7", object: nodes["Beard_7"] },
    { selected: "BE8", object: nodes["Beard_8"] },
  ];

  useEffect(() => {
    beardObjects.forEach((f) => {
      if (
        activeGender === "0" &&
        appliedFace === "F1" &&
        f.selected === appliedBeard
      )
        f.object.visible = true;
      else f.object.visible = false;
    });
  }, [activeGender, appliedBeard, appliedFace]);

  const boyHairObjects = [
    { selected: "BHS1", object: nodes["BoysHair1"] },
    { selected: "BHS2", object: nodes["BoysHair2"] },
    { selected: "BHS3", object: nodes["BoysHair3"] },
    { selected: "BHS4", object: nodes["BoysHair4"] },
    { selected: "BHS6", object: nodes["BoysHair5"] },
    { selected: "BHS7", object: nodes["BoysHair6"] },
    { selected: "BHS8", object: nodes["BoysHair7"] },
    { selected: "BHS9", object: nodes["BoysHair8"] },
    { selected: "BHS10", object: nodes["BoysHair9"] },
    { selected: "BHS12", object: nodes["BoysHair10"] },
  ];

  useEffect(() => {
    boyHairObjects.forEach((f) => {
      if (
        activeGender === "0" &&
        appliedFace === "F1" &&
        f.selected === appliedBoyHairStyle
      ) {
        f.object.visible = true;
        applyColor(f, colorBHS);
      } else f.object.visible = false;
    });
  }, [activeGender, appliedBoyHairStyle, appliedFace, colorBHS]);

  const noseObjects = [
    { selected: "NE1", object: nodes["nose_1"] },
    { selected: "NE2", object: nodes["nose_2"] },
    { selected: "NE3", object: nodes["nose_3"] },
    { selected: "NE4", object: nodes["nose_4"] },
    { selected: "NE5", object: nodes["nose_5"] },
    { selected: "NE6", object: nodes["nose_6"] },
    { selected: "NE7", object: nodes["nose_7"] },
    { selected: "NE8", object: nodes["nose_8"] },
    { selected: "NE9", object: nodes["nose_9"] },
    { selected: "NE10", object: nodes["nose_10"] },
    { selected: "NE11", object: nodes["nose_11"] },
    { selected: "NE12", object: nodes["nose_12"] },
  ];

  useEffect(() => {
    noseObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedNose)
        f.object.visible = true;
      else f.object.visible = false;
    });
  }, [appliedNose, appliedFace, colorBD]);

  const smileObjects = [
    { selected: "MH1", object: nodes["smile_1"] },
    { selected: "MH2", object: nodes["smile_2"] },
    { selected: "MH3", object: nodes["smile_3"] },
    { selected: "MH4", object: nodes["smile_4"] },
    { selected: "MH5", object: nodes["smile_5"] },
    { selected: "MH6", object: nodes["smile_6"] },
    { selected: "MH7", object: nodes["smile_7"] },
    { selected: "MH8", object: nodes["smile_8"] },
  ];

  useEffect(() => {
    smileObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedMouth)
        f.object.visible = true;
      else f.object.visible = false;
    });
  }, [appliedMouth, appliedFace]);

  const accessoryObjects = [
    { selected: "AS1", object: nodes["Apple"] },
    { selected: "AS2", object: nodes["Fish_ball"] },
    { selected: "AS3", object: nodes["Banana"] },
    { selected: "AS4", object: nodes["Book"] },
    { selected: "AS5", object: nodes["Bottle"] },
    { selected: "AS6", object: nodes["Fish"] },
    { selected: "AS7", object: nodes["Orange"] },
    { selected: "AS7", object: nodes["OrangeFBXASC032Leaf"] },
    { selected: "AS8", object: nodes["Pan"] },
    { selected: "AS8", object: nodes["PanFBXASC032Egg"] },
    { selected: "AS9", object: nodes["Sword"] },
    { selected: "AS10", object: nodes["bubble_tea"] },
    { selected: "AS10", object: nodes["bubble_tea1FBXASC032Cup"] },
    { selected: "AS11", object: nodes["Trophy"] },
  ];

  const rt_hand_sidehole = useRef();
  const rt_hand_tophole = useRef();

  useEffect(() => {
    accessoryObjects.forEach((f) => {
      if (f.selected === appliedAccessory) {
        f.object.visible = true;
        if (
          appliedAccessory === "AS1" ||
          appliedAccessory === "AS2" ||
          appliedAccessory === "AS6" ||
          appliedAccessory === "AS7" ||
          appliedAccessory === "AS8" ||
          appliedAccessory === "AS9" ||
          appliedAccessory === "DISABLE_AS"
        ) {
          rt_hand_sidehole.current.visible = false;
          rt_hand_tophole.current.visible = true;
        } else {
          rt_hand_tophole.current.visible = false;
          rt_hand_sidehole.current.visible = true;
        }
      } else f.object.visible = false;
    });
  }, [appliedAccessory]);

  const female_upper_cloth1 = useRef();
  const female_upper_cloth2 = useRef();
  const female_upper_cloth3 = useRef();
  const female_upper_cloth4 = useRef();
  const female_upper_cloth5 = useRef();
  const female_upper_cloth6 = useRef();
  const female_upper_cloth7 = useRef();
  const female_upper_cloth8 = useRef();
  const female_upper_cloth9 = useRef();
  const female_upper_cloth10 = useRef();
  const female_upper_cloth11 = useRef();
  const female_upper_cloth12 = useRef();
  const female_upper_cloth13 = useRef();

  const girlUpperClothObjects = [
    { selected: "GUCS1", object: female_upper_cloth1 },
    { selected: "GUCS2", object: female_upper_cloth2 },
    { selected: "GUCS3", object: female_upper_cloth3 },
    { selected: "GUCS4", object: female_upper_cloth4 },
    { selected: "GUCS5", object: female_upper_cloth5 },
    { selected: "GUCS6", object: female_upper_cloth6 },
    { selected: "GUCS7", object: female_upper_cloth7 },
    { selected: "GUCS8", object: female_upper_cloth8 },
    { selected: "GUCS9", object: female_upper_cloth9 },
    { selected: "GUCS10", object: female_upper_cloth10 },
    { selected: "GUCS11", object: female_upper_cloth11 },
    { selected: "GUCS12", object: female_upper_cloth12 },
    { selected: "GUCS13", object: female_upper_cloth13 },
  ];

  const newUpperCloth = [
    { selected: "NUCS1", object: new_upper_cloth1 },
    { selected: "NUCS2", object: new_upper_cloth2 },
    { selected: "NUCS3", object: new_upper_cloth3 },
    { selected: "NUCS4", object: new_upper_cloth4 },
    { selected: "NUCS5", object: new_upper_cloth5 },
  ];

  useEffect(() => {
    newUpperCloth.forEach((f) => {
      if (
        (activeGender === "0" && f.selected === appliedBoyUpperClothes) ||
        (activeGender === "1" &&
          appliedGirlFullClothes === "NULL" &&
          f.selected === appliedGirlUpperClothes)
      ) {
        f.object.current.visible = true;
      } else {
        f.object.current.visible = false;
      }
    });

    girlUpperClothObjects.forEach((f) => {
      if (
        activeGender === "1" &&
        appliedGirlFullClothes === "NULL" &&
        f.selected === appliedGirlUpperClothes
      )
        f.object.current.visible = true;
      else f.object.current.visible = false;
    });
  }, [
    activeGender,
    appliedGirlFullClothes,
    appliedGirlUpperClothes,
    appliedBoyUpperClothes,
  ]);

  useEffect(() => {
    girlUpperClothObjects.forEach((f) => {
      if (
        activeGender === "1" &&
        appliedGirlFullClothes === "NULL" &&
        f.selected === appliedGirlUpperClothes
      )
        f.object.current.visible = true;
      else f.object.current.visible = false;
    });
  }, [activeGender, appliedGirlFullClothes, appliedGirlUpperClothes]);

  const female_full_cloth1 = useRef();
  const female_full_cloth2 = useRef();
  const female_full_cloth3 = useRef();
  const female_full_cloth4 = useRef();
  const female_full_cloth5 = useRef();

  const girlFullClothObjects = [
    { selected: "GFCS1", object: female_full_cloth1 },
    { selected: "GFCS2", object: female_full_cloth2 },
    { selected: "GFCS3", object: female_full_cloth3 },
    { selected: "GFCS4", object: female_full_cloth4 },
    { selected: "GFCS5", object: female_full_cloth5 },
  ];

  useEffect(() => {
    girlFullClothObjects.forEach((f) => {
      if (activeGender === "1" && f.selected === appliedGirlFullClothes)
        f.object.current.visible = true;
      else f.object.current.visible = false;
    });
  }, [activeGender, appliedGirlFullClothes]);

  const female_lower_cloth1 = useRef();
  const female_lower_cloth2 = useRef();
  const female_lower_cloth3 = useRef();
  const female_lower_cloth4 = useRef();
  const female_lower_cloth5 = useRef();
  const female_lower_cloth6 = useRef();
  const female_lower_cloth7 = useRef();

  const girlLowerClothObjects = [
    { selected: "GLCS1", object: female_lower_cloth1 },
    { selected: "GLCS2", object: female_lower_cloth2 },
    { selected: "GLCS3", object: female_lower_cloth3 },
    { selected: "GLCS4", object: female_lower_cloth4 },
    { selected: "GLCS5", object: female_lower_cloth5 },
    { selected: "GLCS6", object: female_lower_cloth6 },
    { selected: "GLCS7", object: female_lower_cloth7 },
    { selected: "GLCS8", object: new_lower_cloth1 },
  ];

  useEffect(() => {
    girlLowerClothObjects.forEach((f) => {
      if (
        activeGender === "1" &&
        appliedGirlFullClothes === "NULL" &&
        f.selected === appliedGirlLowerClothes
      ) {
        f.object.current.visible = true;
      } else f.object.current.visible = false;
    });
  }, [activeGender, appliedGirlFullClothes, appliedGirlLowerClothes]);

  const shoe1 = useRef();
  const shoe2 = useRef();
  const shoe3 = useRef();
  const shoe4 = useRef();
  const shoe5 = useRef();
  const shoe6 = useRef();
  const shoe7 = useRef();
  const shoe8 = useRef();
  const shoe9 = useRef();
  const shoe10 = useRef();
  const shoe11 = useRef();
  const shoe12 = useRef();
  const shoe13 = useRef();

  const shoeObjects = [
    { selected: "SH1", object: shoe1 },
    { selected: "SH2", object: shoe2 },
    { selected: "SH3", object: shoe3 },
    { selected: "SH4", object: shoe4 },
    { selected: "SH5", object: shoe5 },
    { selected: "SH6", object: shoe6 },
    { selected: "SH7", object: shoe7 },
    { selected: "SH8", object: shoe8 },
    { selected: "SH9", object: shoe9 },
    { selected: "SH10", object: shoe10 },
    { selected: "SH11", object: shoe11 },
    { selected: "SH12", object: shoe12 },
    { selected: "SH13", object: shoe13 },
  ];

  useEffect(() => {
    shoeObjects.forEach((f) => {
      if (f.selected === appliedShoes) f.object.current.visible = true;
      else f.object.current.visible = false;
    });
  }, [appliedShoes]);

  const male_upper_cloth1 = useRef();
  const male_upper_cloth2 = useRef();
  const male_upper_cloth3 = useRef();
  const male_upper_cloth4 = useRef();
  const male_upper_cloth5 = useRef();
  const male_upper_cloth6 = useRef();
  const male_upper_cloth7 = useRef();
  const male_upper_cloth8 = useRef();
  const male_upper_cloth9 = useRef();
  const male_upper_cloth10 = useRef();
  const male_upper_cloth11 = useRef();
  const male_upper_cloth12 = useRef();

  const maleUpperClothObjects = [
    { selected: "BUCS1", object: male_upper_cloth1 },
    { selected: "BUCS2", object: male_upper_cloth2 },
    { selected: "BUCS3", object: male_upper_cloth3 },
    { selected: "BUCS4", object: male_upper_cloth4 },
    { selected: "BUCS5", object: male_upper_cloth5 },
    { selected: "BUCS6", object: male_upper_cloth6 },
    { selected: "BUCS7", object: male_upper_cloth7 },
    { selected: "BUCS8", object: male_upper_cloth8 },
    { selected: "BUCS9", object: male_upper_cloth9 },
    { selected: "BUCS10", object: male_upper_cloth10 },
    { selected: "BUCS11", object: male_upper_cloth11 },
    { selected: "BUCS12", object: male_upper_cloth12 },
  ];

  useEffect(() => {
    maleUpperClothObjects.forEach((f) => {
      if (activeGender === "0" && f.selected === appliedBoyUpperClothes) {
        f.object.current.visible = true;
      } else f.object.current.visible = false;
    });
  }, [activeGender, appliedBoyUpperClothes]);

  const male_lower_cloth1 = useRef();
  const male_lower_cloth2 = useRef();
  const male_lower_cloth3 = useRef();
  const male_lower_cloth4 = useRef();
  const male_lower_cloth5 = useRef();
  const male_lower_cloth6a = useRef();
  const male_lower_cloth6b = useRef();
  const male_lower_cloth7 = useRef();
  const male_lower_cloth8 = useRef();

  const maleLowerClothObjects = [
    { selected: "BLCS1", object: male_lower_cloth1 },
    { selected: "BLCS2", object: male_lower_cloth2 },
    { selected: "BLCS3", object: male_lower_cloth3 },
    { selected: "BLCS4", object: male_lower_cloth4 },
    { selected: "BLCS5", object: male_lower_cloth5 },
    { selected: "BLCS6", object: male_lower_cloth6a },
    // { selected: "BLCS6", object: male_lower_cloth6b },
    { selected: "BLCS7", object: male_lower_cloth7 },
    { selected: "BLCS8", object: male_lower_cloth8 },
  ];

  //Buckle Mesh and Transparency
  const maleLowerObject = [
    {selected:"BLCS6" , object:nodes["lower_cloth_6FBXASC032Buckle"]}
  ]

  useEffect(() => {
    if (
      (activeGender === "0" && appliedBoyLowerClothes === "BLCS9") ||
      (activeGender === "1" &&
        appliedGirlFullClothes === "NULL" &&
        appliedGirlLowerClothes === "GLCS8")
    ) {
      new_lower_cloth1.current.visible = true;
    } else {
      new_lower_cloth1.current.visible = false;
    }

    maleLowerClothObjects.forEach((f) => {
      if (activeGender === "0" && f.selected === appliedBoyLowerClothes) {
        f.object.current.visible = true;
      } else f.object.current.visible = false;
    });
    maleLowerObject.forEach((f) => {
      if (activeGender === "0" && f.selected === appliedBoyLowerClothes) {
        f.object.visible = true;
      } else f.object.visible = false;
    });

  }, [activeGender, appliedBoyLowerClothes, appliedGirlLowerClothes]);

  const glassObjects = [
    { selected: "GLS1", object: nodes["Glasses"] },
    { selected: "GLS1", object: nodes["GlassesFBXASC032Frame"] },
  ];

  useEffect(() => {
    glassObjects.forEach((f) => {
      if (appliedFace === "F1" && f.selected === appliedGlasses)
        f.object.visible = true;
      else f.object.visible = false;
    });
  }, [appliedFace, appliedGlasses]);

  const logoObjects = [
    { selected: "LOGO5", object: nodes["Logo_1"] },
    { selected: "LOGO4", object: nodes["Logo_2"] },
    { selected: "LOGO3", object: nodes["Logo_3"] },
    { selected: "LOGO4", object: nodes["Logo_4"] },
    { selected: "LOGO1", object: nodes["Logo_5"] },
    { selected: "LOGO6", object: nodes["Logo_6"] },
  ];

  useEffect(() => {
    logoObjects.forEach((f) => {
      if (logoVisible && f.selected === appliedLogo) f.object.visible = true;
      else f.object.visible = false;
    });
  }, [logoVisible, appliedLogo]);

  useEffect(() => {
    cheeksObjects.forEach((f) => (f.object.material.transparent = true));
    const animalMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
    animalEyeObjects.forEach((f) => (f.material = animalMaterial));
    faceObjects.forEach((f) => (f.object.material.roughness = 0.5));
    newUpperCloth.forEach((f) => (f.object.current.material.roughness = 0.5));
    boyHairObjects.forEach((f) => (f.object.material.roughness = 1));
    girlHairObjects.forEach((f) => (f.object.material.roughness = 1));
    accessoryObjects.forEach((f) => (f.object.material.roughness = 0.5));
    maleUpperClothObjects.forEach(
      (f) => (f.object.current.material.roughness = 0.5)
    );
    maleLowerClothObjects.forEach(
      (f) => (f.object.current.material.roughness = 0.5)
    );
    girlLowerClothObjects.forEach(
      (f) => (f.object.current.material.roughness = 0.5)
    );
    girlUpperClothObjects.forEach(
      (f) => (f.object.current.material.roughness = 0.5)
    );
    girlFullClothObjects.forEach(
      (f) => (f.object.current.material.roughness = 0.5)
    );
    shoeObjects.forEach((f) => (f.object.current.material.roughness = 0.6));
    nodes["Cheeks_2"].position.set(-0, -0.0003, 0.00017);
    nodes["smile_5"].material.transparent = false;
    nodes["Apple"].material.roughness = 0.55;
    nodes["Head_Geo"].material.roughness = 0.5;
    nodes["Logo_1"].material.roughness = 0.55;
    nodes["upward"].material.roughness = 1;
  }, [appliedLogo]);

  return (
    <group
      avatar={avatar}
      name={avatarConfig}
      ref={group}
      {...props}
      dispose={null}
      position={[0, -1.2, 0]}
    >
      <group>
        <primitive object={nodes.DeformationSystem} />
        <skinnedMesh
          ref={new_lower_cloth1}
          name="pasted__lower_cloth_05FBXASC046001"
          geometry={nodes.pasted__lower_cloth_05FBXASC046001.geometry}
          material={materials.pasted__PantsFBXASC0451_Material}
          skeleton={nodes.pasted__lower_cloth_05FBXASC046001.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        {/* <skinnedMesh
          ref={male_lower_cloth6b}
          name="lower_cloth_6FBXASC032Buckle"
          geometry={nodes.lower_cloth_6FBXASC032Buckle.geometry}
          material={materials.MaleLower6_Buckle_Material}
          skeleton={nodes.lower_cloth_6FBXASC032Buckle.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        /> */}
        <skinnedMesh
          ref={new_upper_cloth4}
          name="upper_cloth_07FBXASC046002"
          geometry={nodes.upper_cloth_07FBXASC046002.geometry}
          material={materials.TFBXASC045shirtFBXASC0452_Material}
          skeleton={nodes.upper_cloth_07FBXASC046002.skeleton}
        />
        <skinnedMesh
          ref={new_upper_cloth1}
          name="upper_cloth_07FBXASC046001"
          geometry={nodes.upper_cloth_07FBXASC046001.geometry}
          material={materials.TFBXASC045shirtFBXASC0451Material}
          skeleton={nodes.upper_cloth_07FBXASC046001.skeleton}
        />
        <skinnedMesh
          ref={new_upper_cloth2}
          name="upper_cloth_07FBXASC046003"
          geometry={nodes.upper_cloth_07FBXASC046003.geometry}
          material={materials.TFBXASC045shirtFBXASC0453_Material}
          skeleton={nodes.upper_cloth_07FBXASC046003.skeleton}
        />
        <skinnedMesh
          ref={new_upper_cloth3}
          name="upper_cloth_07FBXASC046004"
          geometry={nodes.upper_cloth_07FBXASC046004.geometry}
          material={materials.TFBXASC045shirtFBXASC0454_Material}
          skeleton={nodes.upper_cloth_07FBXASC046004.skeleton}
        />
        <skinnedMesh
          ref={new_upper_cloth5}
          name="upper_cloth_07FBXASC046005"
          geometry={nodes.upper_cloth_07FBXASC046005.geometry}
          material={materials.TFBXASC045shirtFBXASC0455_Material}
          skeleton={nodes.upper_cloth_07FBXASC046005.skeleton}
        />
        <skinnedMesh
          ref={rt_hand_tophole}
          name="Rt_Hand_TopHole"
          geometry={nodes.Rt_Hand_TopHole.geometry}
          material={materials.Skin_Nose_Tone_Material}
          skeleton={nodes.Rt_Hand_TopHole.skeleton}
        />
        <skinnedMesh
          ref={rt_hand_sidehole}
          name="Rt_Hand_SideHole"
          geometry={nodes.Rt_Hand_SideHole.geometry}
          material={materials.Skin_Nose_Tone_Material}
          skeleton={nodes.Rt_Hand_SideHole.skeleton}
        />
        <skinnedMesh
          ref={female_lower_cloth4}
          name="GirlLower4"
          geometry={nodes.GirlLower4.geometry}
          material={materials.GirlLower4_Material}
          skeleton={nodes.GirlLower4.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth1}
          name="lower_cloth_01"
          geometry={nodes.lower_cloth_01.geometry}
          material={materials.MaleLower1_Material}
          skeleton={nodes.lower_cloth_01.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe12}
          name="shoe1_Geo"
          geometry={nodes.shoe1_Geo.geometry}
          material={materials.Shoe1_Geo_MAterial}
          skeleton={nodes.shoe1_Geo.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth3}
          name="GirlLower3"
          geometry={nodes.GirlLower3.geometry}
          material={materials.GirlLower3_Material}
          skeleton={nodes.GirlLower3.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe2}
          name="shoe2_Geo"
          geometry={nodes.shoe2_Geo.geometry}
          material={materials.Shoe2_Geo_Material}
          skeleton={nodes.shoe2_Geo.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth1}
          name="GirlLower1"
          geometry={nodes.GirlLower1.geometry}
          material={materials.GirlLower1_Material}
          skeleton={nodes.GirlLower1.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth6}
          name="GirlLower6"
          geometry={nodes.GirlLower6.geometry}
          material={materials.GirlLower6_Material}
          skeleton={nodes.GirlLower6.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth5}
          name="GirlLower5"
          geometry={nodes.GirlLower5.geometry}
          material={materials.GirlLower5_Material}
          skeleton={nodes.GirlLower5.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth2}
          name="lower_cloth_02"
          geometry={nodes.lower_cloth_02.geometry}
          material={materials.MaleLower2_Material}
          skeleton={nodes.lower_cloth_02.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth7}
          name="lower_cloth_07"
          geometry={nodes.lower_cloth_07.geometry}
          material={materials.MaleLower7_Material}
          skeleton={nodes.lower_cloth_07.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth3}
          name="GirlUpper3"
          geometry={nodes.GirlUpper3.geometry}
          material={materials.GirlUpper3_Material}
          skeleton={nodes.GirlUpper3.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth4}
          name="upper_cloth_04"
          geometry={nodes.upper_cloth_04.geometry}
          material={materials.MaleUpper4_Material}
          skeleton={nodes.upper_cloth_04.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth2}
          name="upper_cloth_02"
          geometry={nodes.upper_cloth_02.geometry}
          material={materials.MaleUpper2_Material}
          skeleton={nodes.upper_cloth_02.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth1}
          name="upper_cloth_01"
          geometry={nodes.upper_cloth_01.geometry}
          material={materials.MaleUpper1_Material}
          skeleton={nodes.upper_cloth_01.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth2}
          name="GirlUpper2"
          geometry={nodes.GirlUpper2.geometry}
          material={materials.GirlUpper2_Material}
          skeleton={nodes.GirlUpper2.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth12}
          name="upper_cloth_12"
          geometry={nodes.upper_cloth_12.geometry}
          material={materials.MaleUpper12_Material}
          skeleton={nodes.upper_cloth_12.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth3}
          name="upper_cloth_03"
          geometry={nodes.upper_cloth_03.geometry}
          material={materials.MaleUpper3_Material}
          skeleton={nodes.upper_cloth_03.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth1}
          name="GirlUpper1"
          geometry={nodes.GirlUpper1.geometry}
          material={materials.GirlUpper1_Material}
          skeleton={nodes.GirlUpper1.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth13}
          name="GirlUpper18"
          geometry={nodes.GirlUpper18.geometry}
          material={materials.GirlUpper18_Material}
          skeleton={nodes.GirlUpper18.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth6}
          name="upper_cloth_06"
          geometry={nodes.upper_cloth_06.geometry}
          material={materials.MaleUpper6_Material}
          skeleton={nodes.upper_cloth_06.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth7}
          name="GirlUpper8"
          geometry={nodes.GirlUpper8.geometry}
          material={materials.GirlUpper8_Material}
          skeleton={nodes.GirlUpper8.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth12}
          name="GirlUpper17"
          geometry={nodes.GirlUpper17.geometry}
          material={materials.GirlUpper17_Material}
          skeleton={nodes.GirlUpper17.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth7}
          name="upper_cloth_07"
          geometry={nodes.upper_cloth_07.geometry}
          material={materials.MaleUpper7_Material}
          skeleton={nodes.upper_cloth_07.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth5}
          name="upper_cloth_05"
          geometry={nodes.upper_cloth_05.geometry}
          material={materials.MaleUpper5_Material}
          skeleton={nodes.upper_cloth_05.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth6}
          name="GirlUpper7"
          geometry={nodes.GirlUpper7.geometry}
          material={materials.GirlUpper7_Material}
          skeleton={nodes.GirlUpper7.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth11}
          name="GirlUpper16"
          geometry={nodes.GirlUpper16.geometry}
          material={materials.GirlUpper16_Material}
          skeleton={nodes.GirlUpper16.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth8}
          name="upper_cloth_08"
          geometry={nodes.upper_cloth_08.geometry}
          material={materials.MaleUpper8_Material}
          skeleton={nodes.upper_cloth_08.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth10}
          name="GirlUpper15"
          geometry={nodes.GirlUpper15.geometry}
          material={materials.GirlUpper15_Material}
          skeleton={nodes.GirlUpper15.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth9}
          name="upper_cloth_09"
          geometry={nodes.upper_cloth_09.geometry}
          material={materials.MaleUpper9_Material}
          skeleton={nodes.upper_cloth_09.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth5}
          name="GirlUpper5"
          geometry={nodes.GirlUpper5.geometry}
          material={materials.GirlUpper5_Material}
          skeleton={nodes.GirlUpper5.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth9}
          name="GirlUpper14"
          geometry={nodes.GirlUpper14.geometry}
          material={materials.GirlUpper14_Material}
          skeleton={nodes.GirlUpper14.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth10}
          name="upper_cloth_10"
          geometry={nodes.upper_cloth_10.geometry}
          material={materials.MaleUpper10_Material}
          skeleton={nodes.upper_cloth_10.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth4}
          name="GirlUpper4"
          geometry={nodes.GirlUpper4.geometry}
          material={materials.GirlUpper4_Material}
          skeleton={nodes.GirlUpper4.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_upper_cloth8}
          name="GirlUpper13"
          geometry={nodes.GirlUpper13.geometry}
          material={materials.GirlUpper13_Material}
          skeleton={nodes.GirlUpper13.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_upper_cloth11}
          name="upper_cloth_11"
          geometry={nodes.upper_cloth_11.geometry}
          material={materials.MaleUpper11_Material}
          skeleton={nodes.upper_cloth_11.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_full_cloth5}
          name="GirlUpper12"
          geometry={nodes.GirlUpper12.geometry}
          material={materials.GirlUpper12_Material}
          skeleton={nodes.GirlUpper12.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe6}
          name="Shoes6"
          geometry={nodes.Shoes6.geometry}
          material={materials.Shoe6_Material}
          skeleton={nodes.Shoes6.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_full_cloth4}
          name="GirlUpper11"
          geometry={nodes.GirlUpper11.geometry}
          material={materials.GirlUpper11_Material}
          skeleton={nodes.GirlUpper11.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe5}
          name="Shoes5"
          geometry={nodes.Shoes5.geometry}
          material={materials.Shoe5_Material}
          skeleton={nodes.Shoes5.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_full_cloth2}
          name="GirlUpper9"
          geometry={nodes.GirlUpper9.geometry}
          material={materials.GirlUpper9_Material}
          skeleton={nodes.GirlUpper9.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe4}
          name="Shoes4"
          geometry={nodes.Shoes4.geometry}
          material={materials.Shoe4_Material}
          skeleton={nodes.Shoes4.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_full_cloth3}
          name="GirlCloth10"
          geometry={nodes.GirlCloth10.geometry}
          material={materials.Girl_Cloth_10FBXASC032Material}
          skeleton={nodes.GirlCloth10.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth8}
          name="lower_cloth_08"
          geometry={nodes.lower_cloth_08.geometry}
          material={materials.MaleLower8_MAterial}
          skeleton={nodes.lower_cloth_08.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe1}
          name="Shoes2"
          geometry={nodes.Shoes2.geometry}
          material={materials.Shoe2_Material}
          skeleton={nodes.Shoes2.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe7}
          name="Shoes13"
          geometry={nodes.Shoes13.geometry}
          material={materials.Shoe13_Material}
          skeleton={nodes.Shoes13.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth5}
          name="lower_cloth_05"
          geometry={nodes.lower_cloth_05.geometry}
          material={materials.MaleLower5_Material}
          skeleton={nodes.lower_cloth_05.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe10}
          name="shoe10"
          geometry={nodes.shoe10.geometry}
          material={materials.Shoe10_Material}
          skeleton={nodes.shoe10.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe11}
          name="Shoes11"
          geometry={nodes.Shoes11.geometry}
          material={materials.Shoe11_Material}
          skeleton={nodes.Shoes11.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_full_cloth1}
          name="GirlUpper6"
          geometry={nodes.GirlUpper6.geometry}
          material={materials.GirlUpper6_Material}
          skeleton={nodes.GirlUpper6.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth4}
          name="lower_cloth_04"
          geometry={nodes.lower_cloth_04.geometry}
          material={materials.MaleLower4_Material}
          skeleton={nodes.lower_cloth_04.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe3}
          name="shoe3"
          geometry={nodes.shoe3.geometry}
          material={materials.Shoe3_Material}
          skeleton={nodes.shoe3.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe13}
          name="Shoes9"
          geometry={nodes.Shoes9.geometry}
          material={materials.Shoe9_MAterial}
          skeleton={nodes.Shoes9.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth3}
          name="lower_cloth_03"
          geometry={nodes.lower_cloth_03.geometry}
          material={materials.MaleLower3_Material}
          skeleton={nodes.lower_cloth_03.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe8}
          name="Shoes8"
          geometry={nodes.Shoes8.geometry}
          material={materials.Shoe8_Material}
          skeleton={nodes.Shoes8.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={shoe9}
          name="Shoes7"
          geometry={nodes.Shoes7.geometry}
          material={materials.Shoe7_Material}
          skeleton={nodes.Shoes7.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={male_lower_cloth6a}
          name="lower_cloth_6FBXASC046001"
          geometry={nodes.lower_cloth_6FBXASC046001.geometry}
          material={materials.MaleLower6_Material}
          skeleton={nodes.lower_cloth_6FBXASC046001.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth2}
          name="GirlLower2"
          geometry={nodes.GirlLower2.geometry}
          material={materials.GirlLower2_Material}
          skeleton={nodes.GirlLower2.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          name="Body_Geo"
          geometry={nodes.Body_Geo.geometry}
          material={materials.Skin_Nose_Tone_Material}
          skeleton={nodes.Body_Geo.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          ref={female_lower_cloth7}
          name="GirlLower7"
          geometry={nodes.GirlLower7.geometry}
          material={materials.GirlLower7_Material}
          skeleton={nodes.GirlLower7.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <skinnedMesh
          name="Lt_Hand"
          geometry={nodes.Lt_Hand.geometry}
          material={materials.Skin_Nose_Tone_Material}
          skeleton={nodes.Lt_Hand.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
      </group>
    </group>
  );
}

useLoader.preload(GLTFLoader, "/Models/Web_Rig/avatar.glb");

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export { Model, animationClips };
